.alert-confirm-container {
  font-family: 'Roboto', sans-serif;
  color: #664849;
}

.alert-confirm-main {
  border-radius: 20px;
  width: 85%;
  max-width: 380px;
}

.alert-confirm-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.alert-confirm-header-close {
  top: 8px;
  right: 12px;
  display: none;
}

.alert-confirm-header-close .icon {
  font-size: 24px;
}

.alert-confirm-content {
  padding: 21px 30px 26px;
}

.alert-confirm-content h1 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 16px;
  margin-top: 11px;
}

.alert-confirm-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15;
}

.alert-confirm-content p.confirm-desc {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 6px;
}
.alert-confirm-footer {
  padding: 0 12px 18px;
}

.alert-confirm-footer .ss-confirm-footer,
.alert-confirm-footer .ss-alert-footer {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  width: 88%;
  margin: 0 auto;
}

.alert-confirm-footer .ss-confirm-footer button {
  flex-grow: 1;
  margin-right: 16px;
}

.alert-confirm-footer .ss-confirm-footer button:last-child {
  margin-right: 0;
}

body.tour .alert-confirm-main {
  border-radius: 8px;
  width: 80%;
}

body.tour .alert-confirm-content {
  padding: 0px 18px 20px;
}

body.tour .alert-confirm-content p.confirm-desc {
  color: #cf7b99;
  margin-bottom: 0;
}

body.tour .alert-confirm-footer button.primary {
  height: 30px;
}
