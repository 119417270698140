html {
  font-size: 12px;
}

body {
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

* {
  outline: none;
}

::placeholder {
  color: #d0d0d0;
  font-weight: 400;
}

h1,
h2,
p,
ul,
li,
img,
span,
button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input:-webkit-autofill {
  /* for transparent background */
  -webkit-background-clip: text;
}

select {
  -webkit-appearance: menulist-button;
}

/*
 * buttons
 */
button {
  position: relative;
  cursor: pointer;
  text-align: center;
  outline: none;
  background: none;
  border: 0;
}

button.primary {
  color: white;
  font-size: 1.23rem;
  font-weight: bold;
  line-height: 1.1875;
  text-align: center;
  background-position: center;
  background-color: #cf7b99;
  border: 1px solid #cf7b99;
  border-radius: 19px;
  height: 38px;
  padding: 0 20px;
  transition: background 0.2s, color 0.2s;
  flex-grow: 1;
  margin-right: 16px;
}

button.primary:active,
button.primary:hover {
  color: #cf7b99;
  background-color: white;
  background-size: 100%;
  transition: background 0s;
}

button.primary:last-child {
  margin-right: 0;
}

button.primary.inverted {
  color: #cf7b99;
  background-color: white;
}

button.primary.inverted:active {
  color: white;
  background-color: #cf7b99;
}

button.primary:disabled,
button.primary[disabled] {
  color: white;
  background-color: #cf7b99;
  opacity: 0.5;
}

button.primary.inverted:disabled,
button.primary.inverted[disabled] {
  color: #cf7b99;
  background-color: white;
  opacity: 0.5;
}

button.center {
  display: block;
  margin: 0 auto !important;
}

button.icon-button {
  border: 0;
  background-color: transparent;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 36px;
  height: 36px;
  text-align: left;
  text-indent: 28px;
  font-size: 1.45rem;
}
button.icon-button,
button.icon-button:active,
button.icon-button:hover {
  background-color: transparent;
  background-size: 36px;
  color: white;
}

@media (max-width: 362px) {
  button.primary {
    font-size: 1rem;
    padding: 0 15px;
  }
}

a.link,
button.link {
  font-size: 1.2rem;
  text-decoration: underline;
  color: #4a1a7c;
}

/*
 * picker
 */
.picker .scroll-item {
  font-family: 'Oxygen', sans-serif;
}

.picker .scroll-selector-area {
  border-color: black;
}

/*
 * markdown
 */

.markdown {
  font-size: 1.35rem;
  line-height: 1.2;
}
.markdown h2,
.markdown p {
  padding: 12px 0;
}

.markdown h2 {
  font-weight: bold;
}

.markdown ul {
  list-style: disc;
  margin-left: 16px;
}

.markdown ul li {
  padding-bottom: 10px;
}

@media (max-width: 450px) {
  .markdown {
    font-size: 1.15rem;
  }
}

/*
 * date-picker
 */
.date-picker input {
  text-align: right;
  border: 0;
  font-size: 1.23rem;
  font-weight: bold;
  line-height: 47px;
  background-color: transparent;
  color: #4e2d37;
  padding-right: 10px;
}

.date-picker input:focus,
.date-picker input:active {
  outline: 0;
  padding: 0 14px;
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none;
}

.date-picker.non-select input,
.gender-picker.non-select > span {
  color: #d0d0d0;
  font-weight: 400;
}

/* calendar icon */
.date-picker > div > div > span {
  display: none;
}

div[id$='-picker_modal'] table button {
  font-size: 1.25rem;
}

div[id$='-picker_modal'] table button:hover,
div[id$='-picker_modal'] table button:active,
div[id$='-picker_modal'] table button:focus {
  background-color: #a8657d;
  border: 1px solid #a8657d;
  box-shadow: 0 0 2px #a8657d;
  color: white;
}

div[id$='-picker_modal'] table button[data-selected='true'] {
  background-color: #cf7b99;
}

div[id$='-picker_modal'] h1 {
  color: #cf7b99;
}

/* contact number and owner*/
.input-box > label,
.mobile-field label,
.mobile-owner-field > label {
  width: 100%;
}

@media (max-width: 414px) {
  .mobile-owner-field {
    padding: 10px 0;
    height: 40px !important;
  }

  .mobile-owner-field > label {
    width: 150px;
    line-height: 20px;
  }

  .mobile-owner-field > div,
  .mobile-owner-field > div > span {
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: 375px) {
  .symptom-date-field {
    padding: 10px 0;
    height: 40px !important;
  }

  .symptom-date-field > label {
    width: 150px;
    line-height: 20px;
  }
  .symptom-date-field > div,
  .symptom-date-field > div > span {
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: 340px) {
  .mobile-field {
    padding: 10px 0;
    height: 40px !important;
  }

  .mobile-field > label {
    width: 100px;
    line-height: 20px;
  }
  .mobile-field > div,
  .mobile-field > div > span {
    height: 40px;
    line-height: 40px;
  }
}

/*
 * option group
 */
label.group-option {
  position: relative;
  display: block;
  margin: 12px 0;
}

label.group-option > div > p {
  padding-bottom: 8px;
}

label.group-option p {
  font-weight: normal !important;
}

label.group-option > span {
  right: 0;
}

.group-selection {
  padding: 16px 26px;
}

.group-selection label.group-option:last-child > div > p {
  padding: 0;
}

.group-selection > p:first-child {
  margin-bottom: 16px;
}
.group-title {
  margin-bottom: 5px !important;
}
.group-desc {
  font-style: normal !important;
  font-weight: 100;
  margin-bottom: 18px !important;
}

/*
 * select-label
 */
.select-label {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

/*
 * report
 */
body.report .main-header {
  display: none;
}

body.report .main-footer a.link,
body.report .main-footer button.link {
  color: white;
}

@media print {
  body.report .main-footer {
    display: none;
  }
}

/*
 * login
 */
 .login > div > p {
  font-size: 1.35rem !important;
  margin-bottom: 20px;
}

.login > div > button {
  width: 100%;
}

.login-form {
  margin: 0 -30px;
}

.login-form label {
  width: 100%;
}

.login-field {
  padding: 16px !important;
}